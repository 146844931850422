import React, { useEffect, useState } from "react";
import FloatLabel from "@/Components/FloatLabel";
import { Select } from "antd";
import { companyService } from "@/stores/services/companyService";
import { Mission } from "@/interfaces/Dedication";
import { useAppSelector } from "@/stores/hooks";

interface MissionSelectProps {
  missionSelect: number | null;
  setMissionSelect: any;
}

export default function MissionSelect({
  missionSelect,
  setMissionSelect,
}: MissionSelectProps) {
  const { data: fetchMissions, isLoading: loadMissions } =
    companyService.useFindAllDedicationsQuery();
  const [filteredMissions, setFilteredMissions] = useState<Mission[]>([]);
  const current = useAppSelector((state) => state.util.diagnosisCurrent);

  useEffect(() => {
    if (fetchMissions) {
      setFilteredMissions(fetchMissions);
    }
  }, [fetchMissions]);

  const onSearchMission = (value: string) => {
    const filtered = filteredMissions?.filter((mission) => {
      if (mission.name)
        mission.name.toLowerCase().includes(value.toLowerCase());
    });
    setFilteredMissions(filtered || []);
  };

  const missionOptions = filteredMissions.map((mission) => ({
    value: mission.id,
    label: mission.name.toUpperCase(),
  }));

  return (
    <div className="col-span-6 mt-4">
      <FloatLabel label="Misionalidad de la empresa" obligatory>
        <Select
          showSearch
          optionFilterProp="label"
          onSearch={onSearchMission}
          loading={loadMissions}
          options={missionOptions}
          className="w-full"
          value={missionSelect}
          onChange={(value) => setMissionSelect(value)}
          disabled={current > 0}
        />
      </FloatLabel>
    </div>
  );
}
